$COLOR_A: #ca658d;
$COLOR_B: #79ac4d;


$CHART_A : rgb(215, 2, 6);
$CHART_B : rgb(240, 91, 79);
$CHART_C : rgb(244, 198, 61);
// webfonts

@font-face {
  font-family: 'almoni_neue_regular_aaaRg';
  src: url('resources/webfonts/almonineueregularaaa-regular-webfont.woff2') format('woff2'),
  url('resources/webfonts/almonineueregularaaa-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'almoni_neue_bold_aaabold';
  src: url('resources/webfonts/almonineueboldaaa-bold-webfont.woff2') format('woff2'),
  url('resources/webfonts/almonineueboldaaa-bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

.numnum {
  color: red;
}

.loading-bar {
  border-radius: 5px;
  overflow: hidden;
  position: fixed;
  z-index: 666;
  top: 10px;
  left: 10px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(12px, 12px));
  grid-auto-rows: 1fr;
  width: 48px;

  &::before {
    content: '';
    width: 0;
    padding-bottom: 100%;
    grid-row: 1 / 1;
    grid-column: 1 / 1;
  }

  & > *:first-child {
    grid-row: 1 / 1;
    grid-column: 1 / 1;
  }

  /* Just to make the grid visible */
  & > * {
    background: rgba(0,0,0,0.1);
  }
}

// // Old design
//.loading-bar {
//  position: fixed;
//  top: 0;
//  left: 0;
//  height: 3px;
//  width: 100%;
//  display: flex;
//
//  > b {
//    flex: 1;
//  }
//}

.App {
  font-family: 'almoni_neue_regular_aaaRg', sans-serif;

  .card {
    transition: border 0.6s;
  }

  h1, .card-title, .card-header {
    font-family: 'almoni_neue_bold_aaabold', sans-serif;
  }

  .font-regular {
    font-family: 'almoni_neue_regular_aaaRg', sans-serif;
  }
  .font-bold {
    font-family: 'almoni_neue_bold_aaabold', sans-serif;
  }

  .ibs span {
    display: inline-block;
    margin-left: 5px;
  }

  .search-wrapper {
    position: relative;

    input {
      padding-right: 30px;
    }

    .fa-search {
      position: absolute;
      margin-top: -26px;
      align-items: baseline;
      margin-right: 10px;
      color: #bbb;
    }
  }

  .center-flex {
    display: flex;
    align-items:center;
  }

  // Card deck grid min-width hack
  .card-deck {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-gap: .5rem;
  }

  .navbar {

    border-bottom: 1px solid $COLOR_B;
    margin-bottom: 1rem;

    .navbar-nav .nav-link {
      padding: 0.25rem 0 0.25rem 1.5rem;

      &.active {
        font-weight: bold;
      }
    }

    img.logo {
      display: inline-block;
      height: 50px;
      margin-left: 20px;
    }
  }

  .support-list {
    max-height: calc(100vh - 300px);
    overflow-y: auto;
  }
}

.App.he {
  direction: rtl;
}

// XSmall button
.btn.btn-xs {
  padding: 0.15rem 0.35rem;
  font-size: 0.5rem;
}

.btn.btn-sm {
  padding: 0.1rem 0.35rem;
  font-size: 0.85rem;
}

.heart-rating {
  font-weight: bold;
  font-size: 1.75rem;
}

.alert.alert-confirmation {
  margin-bottom: 0;
  padding: .25rem .5rem;
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .btn {
    padding: .1rem .5rem;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    width: 3rem;
  }
}

// Chart
.ct-label.ct-horizontal.ct-end {
  transform: translate(-50%,0);
  display: block;
  text-align: center;
}
.ct-label.ct-vertical.ct-start {
  transform: translate(0,85%);
  display: block;
  text-align: center;
}

.ct-series {
    &.ct-series-a .ct-label { fill: darken($CHART_A, 25); }
    &.ct-series-b .ct-label { fill: darken($CHART_B, 25); }
    &.ct-series-c .ct-label { fill: darken($CHART_C, 25); }
}